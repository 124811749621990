import { useEffect, useState } from "react";
import { postAxios } from "../../util/apiCall";
import { formatDate } from "../../util/formatDate";

export const useCheckSheetQc = () => {
  const dataAuth = localStorage.getItem("auth");
  const dataUser = JSON.parse(dataAuth);

  const [subMenu, setSubMenu] = useState("laporan");
  const [jenisCheck, setJenisCheck] = useState("");
  const [jenisProduk, setJenisProduk] = useState("Pilih Jenis Produk");
  const [listChecklist, setListChecklist] = useState();

  const [produk, setProduk] = useState([]);
  const [customer, setCustomer] = useState("Pilih Customer");
  const [date, setDate] = useState(formatDate);
  const [shift, setShift] = useState();

  const [produkPick, setProdukPick] = useState();

  const array = [
    {
      jenisCheck: "VISUAL",
      jenisProduk: "CONTAINER",
      data: [
        {
          desc: "Dinding container",
          standard:
            "Lurus, melengkung ke dalam maks. 1mm, YT Series maks. 0,15 mm",
          method: "Diukur dengan feeler gauge",
          isChecked: false,
          catatan: "",
        },
        {
          desc: "Permukaan Atas Dinding Sel",
          standard: "Rata",
          method: "Visual",
          isChecked: false,
          catatan: "",
        },
        {
          desc: "Permukaan Atas Dinding Sel menonjol",
          standard: "Tidak Boleh",
          method: "Visual",
          isChecked: false,
          catatan: "",
        },
        {
          desc: "Permukaan Atas Dinding Sel membenam",
          standard: "Tidak Boleh",
          method: "Visual",
          isChecked: false,
          catatan: "",
        },
        {
          desc: "Permukaan Atas Dinding Sel bahan lebih",
          standard: "Tidak Boleh",
          method: "Visual",
          isChecked: false,
          catatan: "",
        },
        {
          desc: "Permukaan Atas Dinding Sel kurang bahan",
          standard: "Tidak Boleh",
          method: "Visual",
          isChecked: false,
          catatan: "",
        },
        {
          desc: "Permukaan area sablon",
          standard: "Rata",
          method: "Visual",
          isChecked: false,
          catatan: "",
        },
        {
          desc: "Crack (retak)",
          standard: "Tidak Boleh",
          method: "Visual",
          isChecked: false,
          catatan: "",
        },
        {
          desc: "Berlubang",
          standard: "Tidak Boleh",
          method: "Visual",
          isChecked: false,
          catatan: "",
        },
        {
          desc: "Kontaminasi warna",
          standard: "Tidak Boleh",
          method: "Visual",
          isChecked: false,
          catatan: "",
        },
        {
          desc: "Cacat putih",
          standard: "Tidak Boleh",
          method: "Visual",
          isChecked: false,
          catatan: "",
        },
        {
          desc: "Potongan gate",
          standard: "Rata produk & tidak berlubang",
          method: "Visual",
          isChecked: false,
          catatan: "",
        },
        {
          desc: "Runner gate",
          standard: "Tidak berlubang / menonjol",
          method: "Visual",
          isChecked: false,
          catatan: "",
        },
        {
          desc: "Unfill (kurang bahan)",
          standard: "Tidak Boleh",
          method: "Visual",
          isChecked: false,
          catatan: "",
        },
        {
          desc: "Permukaan dasar container",
          standard: "Harus rata / tidak goyang",
          method: "Dicek dengan alas kaca",
        },
        {
          desc: "Partisi bolong",
          standard: "Tidak Boleh",
          method: "Visual",
          isChecked: false,
          catatan: "",
        },
        {
          desc: "Pemasangan sticker",
          standard: "Lurus, tidak miring & tidak gelembung",
          method: "Visual",
          isChecked: false,
          catatan: "",
        },
      ],
    },
    {
      jenisCheck: "VISUAL",
      jenisProduk: "COVER",
      data: [
        {
          desc: "desc 1",
          standard: "std 1",
          method: "metd 1",
          isChecked: false,
          catatan: "",
        },
        {
          desc: "desc 2",
          standard: "std 2",
          method: "metd 2",
          isChecked: false,
          catatan: "",
        },
        {
          desc: "desc 3",
          standard: "std 3",
          method: "metd 3",
          isChecked: false,
          catatan: "",
        },
      ],
    },
  ];

  useEffect(() => {
    postAxios(
      `${process.env.REACT_APP_ENDPOINT}/getListMold`,
      { tim: dataUser.tim },
      dataUser.auth,
      setProduk,
      ""
    );
  }, []);

  useEffect(() => {
    let dataSortItemCheck =
      array &&
      array.find((o) =>
        o.jenisProduk === jenisProduk && o.jenisCheck === jenisCheck ? o : ""
      );
    let mantap = dataSortItemCheck && dataSortItemCheck.data;

    setListChecklist(mantap);
  }, [jenisProduk, jenisCheck]);

  const handleFormChange = (index, event) => {
    let data = [...listChecklist];

    if (event.target.name === "isChecked") {
      data[index][event.target.name] = event.target.checked;
    } else {
      data[index][event.target.name] = event.target.checked;
    }

    setListChecklist(data);
  };

  const validate = () => {
    let data;
    produk &&
      produk.forEach((obj) => {
        if (obj.company === customer) {
          data = obj.data;
        }
      });
    return data;
  };
  const dataProduk = validate();

  let reFormat = [];
  dataProduk &&
    dataProduk.forEach((element) => {
      reFormat.push({ value: element.name, label: element.name });
    });

  return {
    dataUser,
    subMenu,
    setSubMenu,
    jenisCheck,
    setJenisCheck,
    jenisProduk,
    setJenisProduk,
    handleFormChange,
    listChecklist,
    customer,
    setCustomer,
    produk,
    date,
    setDate,
    reFormat,
    produkPick,
    setProdukPick,
    shift,
    setShift,
  };
};
