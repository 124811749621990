import React from "react";
import "./BarcodeYBI.css";
import { useBarcodeYBI } from "./useBarcodeYBI";
import { constantNoMesin } from "../../util/constant";
import PopUpComponent from "../../components/PopUp/PopUpComponent";
import Select from "react-select";
import moment from "moment";

const BarcodeYBI = () => {
  const {
    tglProduksi,
    setTanggalProduksi,
    jumlahBarang,
    setJumlahBarang,
    shift,
    setShift,
    noMesin,
    setNoMesin,
    operator,
    setOperator,
    dataSelect,
    produkPick,
    setProdukPick,
    popUpErrorSubmit,
    setPopUpErrorSubmit,
    popUpSubmit,
    setPopUpSubmit,
    handleKirimBarcode,
    subMenu,
    setSubMenu,
    filterDate,
    setFilterDate,
    handleExportBarcode,
    bagian,
    setBagian,
    dataTemp,
    jumlahBarcode,
    setJumlahBarcode,
  } = useBarcodeYBI();

  const formatDot = (value) => {
    return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
  };

  return (
    <div className="container_dashboard">
      {popUpSubmit ? (
        <PopUpComponent>
          <p>Terima Kasih! Anda Telah Men-update Stock Masuk Bahan.</p>
          <div className="wrapper_button_submit_popup">
            <button
              className="button_popup"
              onClick={() => setPopUpSubmit(false)}
            >
              OK
            </button>
          </div>
        </PopUpComponent>
      ) : (
        ""
      )}
      {popUpErrorSubmit ? (
        <PopUpComponent>
          <p style={{ color: "red" }}>
            Mohon pastikan semua kolom yang Berbintang Merah Terisi !
          </p>
          <div className="wrapper_button_submit_popup">
            <button
              className="button_popup"
              onClick={() => setPopUpErrorSubmit(false)}
            >
              OK
            </button>
          </div>
        </PopUpComponent>
      ) : (
        ""
      )}
      <p className="title_inventory">BARCODE PT. YBI</p>
      <div className="wrapper_sub_menu_history">
        <p
          className={
            subMenu === "Input"
              ? "sub_menu_history border_active_history"
              : "sub_menu_history"
          }
          onClick={() => setSubMenu("Input")}
        >
          Input
        </p>
        <p
          className={
            subMenu === "Riwayat"
              ? "sub_menu_history border_active_history"
              : "sub_menu_history"
          }
          onClick={() => setSubMenu("Riwayat")}
        >
          Riwayat
        </p>
      </div>
      {subMenu === "Input" ? (
        <div className="flex_wrap_request">
          <div className="margin_right_27_qc">
            <p className="font_weight_bold">
              Tgl Produksi<label style={{ color: "red" }}>*</label>
            </p>
            <input
              type="date"
              className="date_pick_memo_ppic"
              value={tglProduksi}
              onChange={(e) => setTanggalProduksi(e.target.value)}
            />
          </div>
          <div className="wrapper_input">
            <p className="font_weight_bold">
              Mesin<label style={{ color: "red" }}>*</label>
            </p>
            <select
              onChange={(e) => setNoMesin(e.target.value)}
              className="input_select"
              defaultValue="Pilih Mesin"
            >
              <option selected={noMesin === "Pilih Mesin"} disabled>
                Pilih Mesin
              </option>
              {constantNoMesin &&
                constantNoMesin.map((data, i) => (
                  <option value={data} key={i} selected={noMesin === data}>
                    {data}
                  </option>
                ))}
            </select>
          </div>
          <div className="wrapper_input">
            <p className="font_weight_bold">
              Nama Barang<label style={{ color: "red" }}>*</label>
            </p>
            <div className="select_item_gbj">
              <Select
                options={dataSelect}
                value={produkPick || ""}
                onChange={setProdukPick}
              />
            </div>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              margin: "6px 0",
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                margin: "12px 24px 12px 0",
              }}
              onClick={() => setShift("1")}
            >
              <input
                type="radio"
                value="1"
                style={{ margin: "0 6px 0 0" }}
                checked={shift === "1"}
              />
              <label style={{ fontSize: "14px" }}>SHIFT 1</label>
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                margin: "12px 0",
              }}
              onClick={() => setShift("2")}
            >
              <input
                type="radio"
                value="2"
                style={{ margin: "0 6px 0 0" }}
                checked={shift === "2"}
              />
              <label style={{ fontSize: "14px" }}>SHIFT 2</label>
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                margin: "12px 0",
              }}
              onClick={() => setShift("3")}
            >
              <input
                type="radio"
                value="3"
                style={{ margin: "0 6px 0 0" }}
                checked={shift === "3"}
              />
              <label style={{ fontSize: "14px" }}>SHIFT 3</label>
            </div>
          </div>
          <div className="wrapper_input">
            <p className="font_weight_bold">
              Operator<label style={{ color: "red" }}>*</label>
            </p>
            <input
              type="text"
              className="input_jumlah"
              placeholder="Masukkan Nama Operator"
              value={operator}
              onChange={(e) => setOperator(e.target.value)}
              onWheel={(e) => e.target.blur()}
            />
          </div>
          <div className="wrapper_input">
            <p className="font_weight_bold">
              Isi per-Kemasan<label style={{ color: "red" }}>*</label>
            </p>
            <input
              type="number"
              className="input_jumlah"
              placeholder="Isi Per-Kemasan (Pcs)"
              value={jumlahBarang}
              onChange={(e) => setJumlahBarang(e.target.value)}
              onWheel={(e) => e.target.blur()}
            />
          </div>
          <div className="wrapper_input">
            <p className="font_weight_bold">Jumlah Barcode (Pcs)</p>
            <input
              type="number"
              className="input_jumlah"
              placeholder="Masukkan Jumlah (Pcs)"
              value={jumlahBarcode}
              onChange={(e) => setJumlahBarcode(e.target.value)}
              onWheel={(e) => e.target.blur()}
            />
          </div>
          <div className="wrapper_button_kirim_request_in">
            <div
              className="button_kirim_request_in"
              onClick={() => handleKirimBarcode()}
            >
              Kirim
            </div>
          </div>
        </div>
      ) : (
        <div
          className="table_inventory_mobile width-table-karantina"
          style={{ position: "absolute", zIndex: "-1" }}
        >
          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
              marginBottom: "24px",
              alignItems: "end",
            }}
          >
            <div className="margin_right_27_qc">
              <p>Pilih Tanggal</p>
              <input
                type="date"
                className="date_pick_memo_ppic"
                style={{ margin: "0px" }}
                value={filterDate}
                onChange={(e) => setFilterDate(e.target.value)}
              />
            </div>
            <div className="width_number_mesin">
              <p>Pilih Bagian</p>
              <select
                defaultValue="Pilih Bagian"
                onChange={(e) => setBagian(e.target.value)}
                className="input_select_memo_ppic"
                style={{ width: "100%" }}
                value={bagian}
              >
                <option selected disabled>
                  Pilih Bagian
                </option>
                <option value="Finishing">Finishing</option>
                <option value="Printing">Printing</option>
              </select>
            </div>
            <p
              className="btn_reset_search_inventory"
              style={{ margin: "0 12px 0 12px" }}
              onClick={() => setBagian("Pilih Bagian")}
            >
              Reset
            </p>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "end",
                marginTop: "12px",
              }}
            >
              <button
                className="export_btn_inventory"
                onClick={() => handleExportBarcode()}
              >
                Export Barcode
              </button>
            </div>
          </div>
          <table style={{ width: "100%" }}>
            <tr>
              <th
                className="table_header"
                style={{
                  background: "#e8e9f8",
                  padding: "12px 6px",
                  width: "6%",
                  borderRight: "1px solid lightgray",
                  width: "auto",
                }}
              >
                No
              </th>
              <th
                className="table_header"
                style={{
                  background: "#e8e9f8",
                  borderRight: "1px solid lightgray",
                  padding: "6px",
                  width: "auto",
                }}
              >
                Tgl Produksi
              </th>
              <th
                className="table_header"
                style={{
                  background: "#e8e9f8",
                  borderRight: "1px solid lightgray",
                  padding: "6px",
                  width: "auto",
                }}
              >
                Part Number
              </th>
              <th
                className="table_header"
                style={{
                  background: "#e8e9f8",
                  borderRight: "1px solid lightgray",
                  padding: "6px",
                  width: "auto",
                }}
              >
                Produk
              </th>
              <th
                className="table_header"
                style={{
                  background: "#e8e9f8",
                  borderRight: "1px solid lightgray",
                  padding: "6px",
                  width: "auto",
                }}
              >
                Jumlah
              </th>
              <th
                className="table_header"
                style={{
                  background: "#e8e9f8",
                  borderRight: "1px solid lightgray",
                  padding: "6px",
                  width: "auto",
                }}
              >
                Operator
              </th>
              <th
                className="table_header"
                style={{
                  background: "#e8e9f8",
                  borderRight: "1px solid lightgray",
                  padding: "6px",
                  width: "auto",
                }}
              >
                Bagian
              </th>
              <th
                className="table_header"
                style={{
                  background: "#e8e9f8",
                  borderRight: "1px solid lightgray",
                  padding: "6px",
                  width: "auto",
                }}
              >
                Update By
              </th>
            </tr>
            {dataTemp &&
              dataTemp.map((e, index) => {
                return (
                  <tr
                    style={
                      index % 2 === 0
                        ? {
                            background: "white",
                            fontSize: "12px",
                          }
                        : {
                            background: "#f9f9f9",
                            fontSize: "12px",
                          }
                    }
                  >
                    <td
                      className="font_td_laporan_bahan_baku"
                      style={{
                        padding: "12px 6px",
                        lineHeight: "18px",
                      }}
                    >
                      {index + 1}
                    </td>

                    <td
                      className="font_td_laporan_bahan_baku"
                      style={{ padding: "12px 6px" }}
                    >
                      {moment(e && e.tglProduksi)
                        .utcOffset("+0700")
                        .format("DD-MM-YYYY")}
                    </td>
                    <td
                      className="font_td_laporan_bahan_baku"
                      style={{ padding: "12px 6px" }}
                    >
                      {e && e.partNumber}
                    </td>
                    <td
                      className="font_td_laporan_bahan_baku"
                      style={{ padding: "12px 6px" }}
                    >
                      {e && e.produk}
                    </td>
                    <td
                      className="font_td_laporan_bahan_baku"
                      style={{ padding: "12px 6px", letterSpacing: "0.5px" }}
                    >
                      {formatDot(e && e.jumlah)}
                    </td>
                    <td
                      className="font_td_laporan_bahan_baku"
                      style={{ padding: "12px 6px" }}
                    >
                      {e && e.operator}
                    </td>
                    <td
                      className="font_td_laporan_bahan_baku"
                      style={{ padding: "12px 6px" }}
                    >
                      {e && e.bagian}
                    </td>
                    <td
                      className="font_td_laporan_bahan_baku"
                      style={{ padding: "12px 6px" }}
                    >
                      {e && e.updatedBy.split(" ").slice(0, 1).join(" ")}
                    </td>
                  </tr>
                );
              })}
          </table>
          <br />
        </div>
      )}
    </div>
  );
};
export default BarcodeYBI;
