import { useEffect, useState } from "react";
import { postAxios } from "../../util/apiCall";
import moment from "moment";
import { formatDate } from "../../util/formatDate";
import * as XLSX from "xlsx";

export const useBarcodeYBI = () => {
  const dataAuth = localStorage.getItem("auth");
  const dataUser = JSON.parse(dataAuth);

  const [dataListBarangYBI, setDataListBarangYBI] = useState();
  const [tglProduksi, setTanggalProduksi] = useState(formatDate);
  const [jumlahBarang, setJumlahBarang] = useState("");
  const [shift, setShift] = useState("");
  const [noMesin, setNoMesin] = useState("Pilih Mesin");
  const [operator, setOperator] = useState("");
  const [produkPick, setProdukPick] = useState();

  const [isReload, setIsReload] = useState(false);
  const [popUpErrorSubmit, setPopUpErrorSubmit] = useState(false);
  const [popUpSubmit, setPopUpSubmit] = useState(false);
  const [subMenu, setSubMenu] = useState("Input");

  const [riwayatBarcode, setRiwayatBarcode] = useState();

  const [filterDate, setFilterDate] = useState(formatDate);
  const [bagian, setBagian] = useState("Pilih Bagian");
  const [jumlahBarcode, setJumlahBarcode] = useState(1);

  useEffect(() => {
    postAxios(
      `${process.env.REACT_APP_ENDPOINT}/getListBarcodeYBI`,
      { tim: dataUser.tim, role: dataUser.role },
      dataUser.auth,
      setDataListBarangYBI,
      ""
    );
    postAxios(
      `${process.env.REACT_APP_ENDPOINT}/getRiwayatBarcode`,
      {
        tim: dataUser.tim,
        role: dataUser.role,
        filterDate: moment(filterDate).utcOffset("+0700").format("YYYY-MM-DD"),
      },
      dataUser.auth,
      setRiwayatBarcode,
      ""
    );
    setIsReload(false);
  }, [isReload, subMenu, filterDate]);

  let dataSelect = [];

  dataListBarangYBI &&
    dataListBarangYBI.forEach((element) => {
      dataSelect.push({ value: element.produk, label: element.produk });
    });

  const validateBarcode = () => {
    let data;
    dataListBarangYBI &&
      dataListBarangYBI.forEach((obj) => {
        const produkPickTemp = produkPick && produkPick.value;
        if (obj.produk === produkPickTemp) {
          data = obj;
        }
      });
    return data;
  };
  const dataBarcode = validateBarcode();

  const handleKirimBarcode = async () => {
    if (
      operator !== "" &&
      !!shift !== "" &&
      !!jumlahBarang !== "" &&
      noMesin !== "Pilih Mesin"
    ) {
      setPopUpSubmit(true);
      await postAxios(
        `${process.env.REACT_APP_ENDPOINT}/kirimBarcode`,
        {
          tim: dataUser.tim,
          tglProduksi: moment(tglProduksi).utcOffset("+0700").format(),
          mesin: noMesin,
          produk: dataBarcode.produk,
          partNumber: dataBarcode.partNumber,
          bagian: dataBarcode.bagian,
          shift: shift,
          operator: operator,
          jumlah: Number(jumlahBarang),
          updateTime: moment().utcOffset("+0700").format(),
          updatedBy: dataUser.name,
          jumlahBarcode: Number(jumlahBarcode),
        },
        dataUser.auth,
        "",
        ""
      );

      setJumlahBarang("");
      setJumlahBarcode(1);
      setShift("");
      setNoMesin("Pilih Mesin");
      setOperator("");
      setProdukPick();
      setIsReload(true);
    } else {
      setPopUpErrorSubmit(true);
    }
  };

  const handleExportBarcode = () => {
    const filteredDataExcel =
      riwayatBarcode &&
      riwayatBarcode.map((obj) => {
        return {
          "po line no": obj.material,
          "part number": obj.partNumber,
          "part name": obj.produk,
          "pack qty": "1",
          qty: obj.jumlah,
          "production date (dd.mm.yyyy)": moment(obj.tglProduksi)
            .utcOffset("+0700")
            .format("DD.MM.YYYY"),
          operator: obj.operator,
          shift: obj.shift,
          machine: obj.mesin,
          "name 1": "",
          "description 1": "",
          "name 2": "",
          "description 2": "",
          "name 3": "",
          "description 3": "",
          "name 4": "",
          "description 4": "",
          "name 5": "",
          "description 5": "",
        };
      });

    const workSheet = XLSX.utils.json_to_sheet(
      filteredDataExcel && filteredDataExcel
    );

    workSheet["!cols"] = [
      { wch: 8 },
      { wch: 14 },
      { wch: 36 },
      { wch: 8 },
      { wch: 10 },
      { wch: 24 },
      { wch: 15 },
      { wch: 5 },
      { wch: 7 },
      { wch: 6 },
      { wch: 11 },
      { wch: 6 },
      { wch: 11 },
      { wch: 6 },
      { wch: 11 },
      { wch: 6 },
      { wch: 11 },
      { wch: 6 },
      { wch: 11 },
    ];
    const workBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workBook, workSheet, "Barang Jadi");
    XLSX.write(workBook, { bookType: "xlsx", type: "buffer" });
    XLSX.write(workBook, { bookType: "xlsx", type: "binary" });
    XLSX.writeFile(
      workBook,
      `Barcode YBI ${moment(filterDate)
        .utcOffset("+0700")
        .format("DD-MM-YYYY")}.xlsx`
    );
  };

  const searchFeature = (params) => {
    if (bagian !== "Pilih Bagian") {
      let searchName = [];
      riwayatBarcode &&
        riwayatBarcode.forEach((value) => {
          if (value.bagian.toLowerCase().includes(params.toLowerCase())) {
            searchName.push(value);
          }
        });
      return searchName;
    } else {
      return riwayatBarcode && riwayatBarcode;
    }
  };
  let dataTemp = searchFeature(bagian);

  return {
    dataUser,
    tglProduksi,
    setTanggalProduksi,
    jumlahBarang,
    setJumlahBarang,
    shift,
    setShift,
    noMesin,
    setNoMesin,
    operator,
    setOperator,
    dataSelect,
    produkPick,
    setProdukPick,
    popUpErrorSubmit,
    setPopUpErrorSubmit,
    popUpSubmit,
    setPopUpSubmit,
    handleKirimBarcode,
    subMenu,
    setSubMenu,
    filterDate,
    setFilterDate,
    handleExportBarcode,
    bagian,
    setBagian,
    dataTemp,
    jumlahBarcode,
    setJumlahBarcode,
  };
};
