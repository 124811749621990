import React, { useState, useEffect } from "react";
import TimLogo from "../../Assets/TimLogo.png";
import { postAxios } from "../../util/apiCall";
import { useHistory } from "react-router-dom";
import "./MenuComponent.css";

const MenuComponent = ({ setMenu, menu }) => {
  let history = useHistory();
  const dataAuth = localStorage.getItem("auth");
  const dataUser = JSON.parse(dataAuth);
  const [openSubMenu, setOpenSubMenu] = useState(false);
  const [isOn, setIsOn] = useState(false);
  const [isReload, setIsReload] = useState(false);

  useEffect(() => {
    postAxios(
      `${process.env.REACT_APP_ENDPOINT}/emergency`,
      { tim: dataUser.tim, dataType: "emergency", type: "get" },
      dataUser.auth,
      setIsOn,
      ""
    );
    setIsReload(false);
  }, [isReload]);

  const handleLogout = () => {
    postAxios(
      `${process.env.REACT_APP_ENDPOINT}/logout`,
      { tim: dataUser.tim, id: dataUser.id },
      dataUser.auth,
      "",
      ""
    );
    localStorage.removeItem("auth");
    history.push("/login");
  };

  const pickMenu = (data) => {
    setMenu(data);
    setOpenSubMenu(false);
  };

  const handlePressToggle = () => {
    postAxios(
      `${process.env.REACT_APP_ENDPOINT}/emergency`,
      {
        tim: dataUser.tim,
        dataType: "emergency",
        type: "edit",
        isToggle: isOn,
      },
      dataUser.auth,
      "",
      ""
    );
    if (isOn) {
      setIsOn(false);
    } else if (!isOn) {
      setIsOn(true);
    }
    setIsReload(true);
  };

  return (
    <>
      <div
        className="menu_button"
        onClick={() =>
          openSubMenu ? setOpenSubMenu(false) : setOpenSubMenu(true)
        }
      >
        Menu
      </div>
      <div className={openSubMenu ? "wrapper_sub_menu" : "none"}>
        <div className="parent_logo">
          <img src={TimLogo} className="width_logo" alt="logo" />
        </div>
        <div className="parent_menu">
          <div className="wrapper_menu">
            {dataUser.role === "p3_user" ||
            dataUser.role === "warehouse_admin" ||
            dataUser.role === "warehouse_gbj_admin" ||
            dataUser.role === "warehouse_gbj_user" ||
            dataUser.role === "security" ? (
              ""
            ) : (
              <p
                className={menu === "Dashboard" ? "menu_active" : "menu"}
                onClick={() => pickMenu("Dashboard")}
              >
                Home
              </p>
            )}

            {dataUser.role === "super_user" ? (
              <p
                className={menu === "CheckSheetQc" ? "menu_active" : "menu"}
                onClick={() => pickMenu("CheckSheetQc")}
              >
                Check Sheet Qc
              </p>
            ) : (
              ""
            )}

            {dataUser.role === "super_user" ||
            dataUser.role === "qc_admin" ||
            dataUser.role === "qc_user" ||
            dataUser.role === "sp_admin" ||
            dataUser.role === "sp_user" ||
            dataUser.role === "pr_admin" ||
            dataUser.role === "pr_user" ||
            dataUser.role === "warehouse_gbj_admin" ||
            dataUser.role === "warehouse_admin" ||
            dataUser.role === "produksi_admin" ||
            dataUser.role === "produksi_user" ||
            dataUser.role === "produksi_leader" ||
            dataUser.role === "ppic_admin" ? (
              <p
                className={menu === "Karantina" ? "menu_active" : "menu"}
                onClick={() => pickMenu("Karantina")}
              >
                Karantina
              </p>
            ) : (
              ""
            )}

            {dataUser.role === "super_user" ||
            dataUser.role === "produksi_leader" ||
            dataUser.role === "ppic_admin" ||
            dataUser.role === "produksi_admin" ? (
              <p
                className={menu === "NgSetting" ? "menu_active" : "menu"}
                onClick={() => pickMenu("NgSetting")}
              >
                NG Setting
              </p>
            ) : (
              ""
            )}
            {dataUser.role === "super_user" ||
            dataUser.role === "warehouse_admin" ||
            dataUser.role === "warehouse_gbj_admin" ||
            dataUser.role === "warehouse_mixing" ||
            dataUser.role === "warehouse_crusher" ||
            dataUser.role === "ppic_admin" ||
            dataUser.role === "ppic_user" ? (
              <p
                className={menu === "Inventory" ? "menu_active" : "menu"}
                onClick={() => pickMenu("Inventory")}
              >
                Inventory
              </p>
            ) : (
              ""
            )}
            {dataUser.role === "super_user" ||
            dataUser.role === "warehouse_admin" ||
            dataUser.role === "ppic_admin" ||
            dataUser.role === "ppic_user" ||
            dataUser.role === "warehouse_mixing" ||
            dataUser.role === "warehouse_crusher" ? (
              <p
                className={menu === "InputBahan" ? "menu_active" : "menu"}
                onClick={() => pickMenu("InputBahan")}
              >
                Input Bahan
              </p>
            ) : (
              ""
            )}
            {dataUser.role === "super_user" ||
            dataUser.role === "warehouse_admin" ||
            dataUser.role === "ppic_admin" ||
            dataUser.role === "ppic_user" ||
            dataUser.role === "warehouse_gbj_admin" ? (
              <p
                className={menu === "InputBarangJadi" ? "menu_active" : "menu"}
                onClick={() => pickMenu("InputBarangJadi")}
              >
                Input GBJ
              </p>
            ) : (
              ""
            )}
            {dataUser.role === "p3_user" ||
            dataUser.role === "produksi_user" ||
            dataUser.role === "pr_user" ||
            dataUser.role === "sp_user" ||
            dataUser.role === "sp_admin" ||
            dataUser.role === "security" ? (
              ""
            ) : (
              <p
                className={menu === "History" ? "menu_active" : "menu"}
                onClick={() => pickMenu("History")}
              >
                History
              </p>
            )}
            {dataUser.role === "super_user" ||
            dataUser.role === "warehouse_admin" ? (
              <p
                className={menu === "ListBahan" ? "menu_active" : "menu"}
                onClick={() => pickMenu("ListBahan")}
              >
                Bahan
              </p>
            ) : (
              ""
            )}
            {dataUser.role === "super_user" ||
            dataUser.role === "ppic_admin" ? (
              <p
                className={menu === "MemoPPIC" ? "menu_active" : "menu"}
                onClick={() => pickMenu("MemoPPIC")}
              >
                PPIC
              </p>
            ) : (
              ""
            )}
            {dataUser.role === "super_user" ||
            dataUser.role === "qc_admin" ||
            dataUser.role === "qc_user" ? (
              <p
                className={menu === "QC" ? "menu_active" : "menu"}
                onClick={() => pickMenu("QC")}
              >
                QC
              </p>
            ) : (
              ""
            )}
            {dataUser.role === "super_user" ||
            dataUser.role === "qc_user" ||
            dataUser.role === "qc_admin" ||
            dataUser.role === "ppic_admin" ? (
              <p
                className={menu === "Patrol" ? "menu_active" : "menu"}
                style={{ lineHeight: "16px" }}
                onClick={() => pickMenu("Patrol")}
              >
                Patrol
              </p>
            ) : (
              ""
            )}
            {dataUser.role === "super_user" || dataUser.role === "security" ? (
              <p
                className={menu === "Security" ? "menu_active" : "menu"}
                style={{ lineHeight: "16px" }}
                onClick={() => pickMenu("Security")}
              >
                Security
              </p>
            ) : (
              ""
            )}
            {dataUser.role === "super_user" ||
            dataUser.role === "ppic_admin" ? (
              <p
                className={menu === "Produk" ? "menu_active" : "menu"}
                onClick={() => pickMenu("Produk")}
              >
                Produk
              </p>
            ) : (
              ""
            )}
            {dataUser.role === "super_user" ||
            dataUser.role === "mold_admin" ||
            dataUser.role === "ppic_admin" ||
            dataUser.role === "qc_admin" ||
            dataUser.role === "produksi_admin" ? (
              <p
                className={menu === "Mold" ? "menu_active" : "menu"}
                onClick={() => pickMenu("Mold")}
              >
                Mold
              </p>
            ) : (
              ""
            )}
            {dataUser.role === "super_user" ||
            dataUser.role === "produksi_user" ? (
              <p
                className={menu === "Operator" ? "menu_active" : "menu"}
                onClick={() => pickMenu("Operator")}
              >
                Operator
              </p>
            ) : (
              ""
            )}
            {dataUser.role === "super_user" ||
            dataUser.role === "pr_user" ||
            dataUser.role === "produksi_admin" ||
            dataUser.role === "pr_admin" ||
            dataUser.role === "sp_user" ||
            dataUser.role === "sp_admin" ? (
              <p
                className={menu === "SP" ? "menu_active" : "menu"}
                onClick={() => pickMenu("SP")}
              >
                SP
              </p>
            ) : (
              ""
            )}
            {dataUser.role === "super_user" ||
            dataUser.role === "pr_admin" ||
            dataUser.role === "sp_admin" ? (
              <p
                className={menu === "listProdukSP" ? "menu_active" : "menu"}
                onClick={() => pickMenu("listProdukSP")}
              >
                Produk SP
              </p>
            ) : (
              ""
            )}
            {dataUser.role === "super_user" ||
            dataUser.role === "produksi_leader" ? (
              <p
                className={menu === "ApprovalLaporan" ? "menu_active" : "menu"}
                style={{ lineHeight: "16px" }}
                onClick={() => pickMenu("ApprovalLaporan")}
              >
                Approval Laporan
              </p>
            ) : (
              ""
            )}
            {dataUser.role === "super_user" ||
            dataUser.role === "sp_admin" ||
            dataUser.role === "pr_admin" ? (
              <p
                className={
                  menu === "ApprovalLaporanSP" ? "menu_active" : "menu"
                }
                style={{ lineHeight: "16px" }}
                onClick={() => pickMenu("ApprovalLaporanSP")}
              >
                Approval Laporan SP
              </p>
            ) : (
              ""
            )}
            {dataUser.role === "super_user" ||
            dataUser.role === "sp_admin" ||
            dataUser.role === "pr_admin" ? (
              <p
                className={menu === "checkSheet" ? "menu_active" : "menu"}
                style={{ lineHeight: "16px" }}
                onClick={() => pickMenu("checkSheet")}
              >
                Check Sheet
              </p>
            ) : (
              ""
            )}
            {dataUser.role === "super_user" ||
            dataUser.role === "pr_admin" ||
            dataUser.role === "sp_admin" ||
            dataUser.role === "warehouse_gbj_admin" ||
            dataUser.role === "ppic_user" ? (
              <p
                className={menu === "BarcodeYBI" ? "menu_active" : "menu"}
                style={{ lineHeight: "16px" }}
                onClick={() => pickMenu("BarcodeYBI")}
              >
                Barcode YBI
              </p>
            ) : (
              ""
            )}
            {dataUser.role === "super_user" ||
            dataUser.role === "ppic_admin" ||
            dataUser.role === "ppic_user" ? (
              <p
                className={menu === "PO" ? "menu_active" : "menu"}
                style={{ lineHeight: "16px" }}
                onClick={() => pickMenu("PO")}
              >
                PO
              </p>
            ) : (
              ""
            )}
            {dataUser.role === "super_user" ||
            dataUser.role === "ppic_admin" ? (
              <div class="toggle-button-cover">
                <div class="button-cover">
                  <div class="button r" id="button-1">
                    <input
                      type="checkbox"
                      class="checkbox"
                      checked={!isOn}
                      onClick={() => handlePressToggle()}
                    />
                    <div class="knobs"></div>
                    <div class="layer"></div>
                  </div>
                </div>
              </div>
            ) : (
              ""
            )}
          </div>
          <button className="button_logout" onClick={() => handleLogout()}>
            Logout
          </button>
        </div>
      </div>
    </>
  );
};
export default MenuComponent;
