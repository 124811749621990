import { useState, useEffect } from "react";
import { postAxios } from "../../util/apiCall";
import { today, getDateUtil } from "../../util/formatDate";
import { v4 as uuidv4 } from "uuid";
import * as XLSX from "xlsx";

export const useNgSetting = () => {
  const v4Id = uuidv4();
  const dataAuth = localStorage.getItem("auth");
  const dataUser = JSON.parse(dataAuth);

  const [dateLaporan, setDateLaporan] = useState(today);
  const [companyPick, setCompanyPick] = useState("Pilih Customer");
  const [subMenuLaporanBahanBaku, setSubMenuLaporanBahanBaku] =
    useState("Produksi");

  const [mesin, setMesin] = useState("Pilih Mesin");
  const [errMsg, setErrMsg] = useState("");
  const [popUpErr, setPopUpErr] = useState(false);
  const [jumlahReject, setJumlahReject] = useState("");
  const [isErrorProduksi, setIsErrorProduksi] = useState(false);
  const [dataRejectSetting, setDataRejectSetting] = useState();
  const [tipeBahan, setTipeBahan] = useState("Pilih Tipe Bahan");
  const [shift, setShift] = useState("Pilih Shift");
  const [tipeNg, setTipeNg] = useState("Pilih Tipe NG");
  const [jenisSetting, setJenisSetting] = useState("Pilih Jenis Setting");
  const [listProduk, setListProduk] = useState();
  const [produkReject, setProdukReject] = useState("Pilih Produk");
  const [filterShift, setFilterShift] = useState("Pilih Shift");
  const [kepalaShift, setKepalaShift] = useState("Pilih Kepala Shift");
  const [jenisNGLain, setJenisNGLain] = useState("");

  const [dataExport, setDataExport] = useState();
  const [dateExport, setDateExport] = useState(today);
  const [dateUntil, setDateUntil] = useState(today);
  const [isPopUp, setIsPopUp] = useState(false);

  useEffect(() => {
    if (
      subMenuLaporanBahanBaku === "Produksi" ||
      subMenuLaporanBahanBaku === "RiwayatProduksi"
    ) {
      postAxios(
        `${process.env.REACT_APP_ENDPOINT}/getListMold`,
        { tim: dataUser.tim },
        dataUser.auth,
        setListProduk,
        ""
      );
    }
  }, [dateLaporan, subMenuLaporanBahanBaku]);

  useEffect(() => {
    if (subMenuLaporanBahanBaku === "RiwayatProduksi") {
      postAxios(
        `${process.env.REACT_APP_ENDPOINT}/getLaporanRejectSetting`,
        {
          tim: dataUser.tim,
          date: getDateUtil(dateLaporan),
          shift: filterShift,
        },
        dataUser.auth,
        setDataRejectSetting,
        ""
      );
    }
  }, [dateLaporan, subMenuLaporanBahanBaku, filterShift]);

  const dataPickListProduk = () => {
    let data;
    listProduk &&
      listProduk.forEach((element) => {
        if (element.company === companyPick) {
          data = element;
        }
      });
    return data;
  };

  const dataListProduk = dataPickListProduk();

  const handlePopUp = () => {
    setPopUpErr(false);
    setErrMsg("");
  };

  const handleKirimReject = () => {
    if (
      mesin !== "Pilih Mesin" &&
      jumlahReject !== "" &&
      jenisSetting !== "Pilih Jenis Setting" &&
      tipeNg !== "Pilih Tipe NG" &&
      shift !== "Pilih Shift" &&
      tipeBahan !== "Pilih Tipe Bahan" &&
      produkReject !== "Pilih Produk"
    ) {
      postAxios(
        `${process.env.REACT_APP_ENDPOINT}/addRejectSetting`,
        {
          id: v4Id,
          tim: dataUser.tim,
          customer: companyPick,
          date: getDateUtil(dateLaporan),
          mesin: mesin,
          jumlah: Number(jumlahReject),
          creator: dataUser.name,
          jenisSetting: jenisSetting,
          produk: produkReject,
          tipeNg: tipeNg === "Lain - Lain" ? jenisNGLain : tipeNg,
          shift: shift,
          kepalaShift: kepalaShift,
          tipeBahan: tipeBahan,
          produkReject: produkReject,
        },
        dataUser.auth,
        "",
        ""
      );

      setProdukReject("Pilih Produk");
      setErrMsg("Data Telah Dikirim!");
      setCompanyPick("Pilih Customer");
      setPopUpErr(true);
      setMesin("Pilih Mesin");
      setJumlahReject("");
      setIsErrorProduksi(false);
      setJenisSetting("Pilih Jenis Setting");
      setTipeNg("Pilih Tipe NG");
      setShift("Pilih Shift");
      setTipeBahan("Pilih Tipe Bahan");
      setJenisNGLain("");
    } else {
      setIsErrorProduksi(true);
    }
  };

  const exportProduksi = () => {
    postAxios(
      `${process.env.REACT_APP_ENDPOINT}/exportRejectProduksi`,
      {
        tim: dataUser.tim,
        date: getDateUtil(dateExport),
        dateUntil: getDateUtil(dateUntil),
        type: "setting",
      },
      dataUser.auth,
      setDataExport,
      ""
    );

    const sort =
      dataExport &&
      dataExport.sort(function (a, b) {
        if (Number(a.isoDate) < Number(b.isoDate)) {
          return -1;
        }
        if (Number(a.isoDate) > Number(b.isoDate)) {
          return 1;
        }
        if (Number(a.shift) > Number(b.shift)) {
          return -1;
        }
        if (Number(a.shift) < Number(b.shift)) {
          return 1;
        }
        return 0;
      });

    const filteredDataExcel =
      sort &&
      sort.map((obj) => {
        let firstFind =
          listProduk && listProduk.find((o) => o.company === obj.customer);
        let secondFind = firstFind && firstFind.data[obj.produkReject];

        return {
          TANGGAL: obj.date,
          MC: obj.mesin,
          PRODUK: secondFind.name,
          WARNA: secondFind.color,
          "TIPE NG": obj.tipeNg,
          JUMLAH: obj.jumlah,
          SETTING: obj.jenisSetting,
          SHIFT: obj.kepalaShift,
        };
      });

    const workSheet = XLSX.utils.json_to_sheet(
      filteredDataExcel && filteredDataExcel
    );

    workSheet["!cols"] = [
      { wch: 16 },
      { wch: 4 },
      { wch: 40 },
      { wch: 13 },
      { wch: 14 },
      { wch: 8 },
      { wch: 15 },
      { wch: 12 },
    ];
    const workBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workBook, workSheet, "attendance");
    XLSX.write(workBook, { bookType: "xlsx", type: "buffer" });
    XLSX.write(workBook, { bookType: "xlsx", type: "binary" });
    XLSX.writeFile(
      workBook,
      `Laporan Reject Produksi ${getDateUtil(dateExport)} - ${getDateUtil(
        dateUntil
      )}.xlsx`
    );

    setIsPopUp(false);
    setDateExport(today);
    setDateUntil(today);
  };

  return {
    dataUser,
    subMenuLaporanBahanBaku,
    setSubMenuLaporanBahanBaku,
    companyPick,
    setCompanyPick,
    mesin,
    setMesin,
    dateLaporan,
    setDateLaporan,
    errMsg,
    popUpErr,
    handlePopUp,
    jumlahReject,
    setJumlahReject,
    handleKirimReject,
    isErrorProduksi,
    dataRejectSetting,
    tipeBahan,
    setTipeBahan,
    shift,
    setShift,
    tipeNg,
    setTipeNg,
    jenisSetting,
    setJenisSetting,
    dataListProduk,
    produkReject,
    setProdukReject,
    listProduk,
    filterShift,
    setFilterShift,
    kepalaShift,
    setKepalaShift,
    jenisNGLain,
    setJenisNGLain,
    isPopUp,
    setIsPopUp,
    dateExport,
    setDateExport,
    dateUntil,
    setDateUntil,
    exportProduksi,
  };
};
