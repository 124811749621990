import { useEffect, useState } from "react";
import { postAxios } from "../../util/apiCall";
import moment from "moment";
import { formatDate } from "../../util/formatDate";

export const useInputBarangJadi = () => {
  const dataAuth = localStorage.getItem("auth");
  const dataUser = JSON.parse(dataAuth);

  const [dataListBarangJadi, setDataListBarangJadi] = useState();
  const [companyPickInputGbj, setCompanyPickInputGbj] = useState("");
  const [jumlahInputGbj, setJumlahInputGbj] = useState("");
  const [keteranganGbj, setKeteranganGbj] = useState("");
  const [isReload, setIsReload] = useState(false);
  const [produkPick, setProdukPick] = useState("");

  const [popUpSubmit, setPopUpSubmit] = useState(false);
  const [popUpErrorSubmit, setPopUpErrorSubmit] = useState(false);

  const [historyBarangJadiAksesoris, setHistoryBarangJadiAksesoris] =
    useState();
  const [produkPickFilter, setProdukPickFilter] = useState("");
  const [filterDate, setFilterDate] = useState(formatDate);
  const [dateSubmitBarangJadi, setDateSubmitBarangJadi] = useState(formatDate);
  const [filterStatus, setFilterStatus] = useState("Status");

  const [statusBarang, setStatusBarang] = useState("Masuk");

  useEffect(() => {
    setProdukPick("");
  }, [companyPickInputGbj]);

  useEffect(() => {
    postAxios(
      `${process.env.REACT_APP_ENDPOINT}/getListBarangJadi`,
      { tim: dataUser.tim, role: dataUser.role },
      dataUser.auth,
      setDataListBarangJadi,
      ""
    );
    postAxios(
      `${process.env.REACT_APP_ENDPOINT}/getLogBarangJadiAksesoris`,
      { tim: dataUser.tim, date: filterDate },
      dataUser.auth,
      setHistoryBarangJadiAksesoris,
      ""
    );
    setProdukPickFilter("");
    setIsReload(false);
  }, [isReload, filterDate]);

  const validateBarangJadi = () => {
    let data;
    dataListBarangJadi &&
      dataListBarangJadi.forEach((obj) => {
        if (obj.company === companyPickInputGbj) {
          data = obj.data;
        }
      });
    return data;
  };
  const dataBarangJadi = validateBarangJadi();

  let reFormat = [];
  dataBarangJadi &&
    dataBarangJadi.forEach((element) => {
      reFormat.push({ value: element.jenis, label: element.material });
    });

  const handleKirimInputGbj = async () => {
    if (
      companyPickInputGbj !== "" &&
      jumlahInputGbj !== "" &&
      produkPick !== ""
    ) {
      setPopUpSubmit(true);
      await postAxios(
        `${process.env.REACT_APP_ENDPOINT}/addGudangBarangJadi`,
        {
          company: companyPickInputGbj,
          role: dataUser.role,
          total: Number(jumlahInputGbj),
          keterangan: keteranganGbj,
          material: produkPick.label,
          type: produkPick.value,
          statusBahan: statusBarang,
          updatedBy: dataUser.name,
          tim: dataUser.tim,
          updateTime: moment().utcOffset("+0700").format(),
          masukTime: moment(dateSubmitBarangJadi).utcOffset("+0700").format(),
          month: moment().utcOffset("+0700").month() + 1,
        },
        dataUser.auth,
        "",
        ""
      );
      setIsReload(true);
      setKeteranganGbj("");
      setJumlahInputGbj("");
    } else {
      setPopUpErrorSubmit(true);
    }
  };

  const searchFeature = (params) => {
    if (produkPickFilter !== "") {
      let searchName = [];
      if (filterStatus === "Status") {
        historyBarangJadiAksesoris &&
          historyBarangJadiAksesoris.forEach((value) => {
            if (
              value.material.toLowerCase().includes(params.value.toLowerCase())
            ) {
              searchName.push(value);
            }
          });
        return searchName;
      } else {
        historyBarangJadiAksesoris &&
          historyBarangJadiAksesoris.forEach((value) => {
            if (
              value.material
                .toLowerCase()
                .includes(params.value.toLowerCase()) &&
              value.statusBahan.includes(filterStatus)
            ) {
              searchName.push(value);
            }
          });
        return searchName;
      }
    } else {
      return historyBarangJadiAksesoris && historyBarangJadiAksesoris;
    }
  };
  let dataTemp = searchFeature(produkPickFilter);

  return {
    dataUser,
    popUpSubmit,
    popUpErrorSubmit,
    setCompanyPickInputGbj,
    setJumlahInputGbj,
    jumlahInputGbj,
    keteranganGbj,
    setKeteranganGbj,
    companyPickInputGbj,
    dataListBarangJadi,
    reFormat,
    setProdukPick,
    produkPick,
    handleKirimInputGbj,
    setPopUpSubmit,
    setPopUpErrorSubmit,
    filterDate,
    setFilterDate,
    historyBarangJadiAksesoris,
    dateSubmitBarangJadi,
    setDateSubmitBarangJadi,
    produkPickFilter,
    setProdukPickFilter,
    dataTemp,
    filterStatus,
    setFilterStatus,
    statusBarang,
    setStatusBarang,
    setIsReload,
  };
};
