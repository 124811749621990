import React from "react";
import "./InputBarangJadi.css";
import { useInputBarangJadi } from "./useInputBarangJadi";
import PopUpComponent from "../../components/PopUp/PopUpComponent";
import Select from "react-select";
import RefreshIcon from "../../Assets/refresh-icon.png";

const InputBarangJadi = () => {
  const {
    dataUser,
    popUpSubmit,
    popUpErrorSubmit,
    setCompanyPickInputGbj,
    setJumlahInputGbj,
    setKeteranganGbj,
    jumlahInputGbj,
    keteranganGbj,
    companyPickInputGbj,
    dataListBarangJadi,
    reFormat,
    setProdukPick,
    produkPick,
    handleKirimInputGbj,
    setPopUpSubmit,
    setPopUpErrorSubmit,
    filterDate,
    setFilterDate,
    historyBarangJadiAksesoris,
    dateSubmitBarangJadi,
    setDateSubmitBarangJadi,
    produkPickFilter,
    setProdukPickFilter,
    dataTemp,
    filterStatus,
    setFilterStatus,
    setStatusBarang,
    statusBarang,
    setIsReload,
  } = useInputBarangJadi();

  const formatDot = (value) => {
    return value && value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
  };

  let dataFilterProduk = [];

  historyBarangJadiAksesoris &&
    historyBarangJadiAksesoris.forEach((element) => {
      dataFilterProduk.push({
        label: element.material,
        value: element.material,
      });
    });

  let resultReduce = dataFilterProduk.reduce((unique, o) => {
    if (!unique.some((obj) => obj.label === o.label && obj.value === o.value)) {
      unique.push(o);
    }
    return unique;
  }, []);

  const handleReset = () => {
    setFilterStatus("Status");
    setProdukPickFilter("");
  };
  const sum =
    dataTemp &&
    dataTemp.reduce(function (s, a) {
      return s + a.dataWeight;
    }, 0);

  return (
    <div className="container_dashboard">
      {popUpSubmit ? (
        <PopUpComponent>
          <p>Terima Kasih! Anda Telah Men-update Stock Masuk Bahan.</p>
          <div className="wrapper_button_submit_popup">
            <button
              className="button_popup"
              onClick={() => setPopUpSubmit(false)}
            >
              OK
            </button>
          </div>
        </PopUpComponent>
      ) : (
        ""
      )}
      {popUpErrorSubmit ? (
        <PopUpComponent>
          <p style={{ color: "red" }}>
            Mohon pastikan semua kolom yang Berbintang Merah Terisi !
          </p>
          <div className="wrapper_button_submit_popup">
            <button
              className="button_popup"
              onClick={() => setPopUpErrorSubmit(false)}
            >
              OK
            </button>
          </div>
        </PopUpComponent>
      ) : (
        ""
      )}
      <p className="title_inventory">INPUT GUDANG BARANG JADI</p>
      <>
        <div className="flex_wrap_request">
          <div className="margin_right_27_qc">
            <p>Pilih Tanggal</p>
            <input
              type="date"
              className="date_pick_memo_ppic"
              value={dateSubmitBarangJadi}
              onChange={(e) => setDateSubmitBarangJadi(e.target.value)}
            />
          </div>
          {dataUser.role === "ppic_user" ||
          dataUser.role === "super_user" ||
          dataUser.role === "warehouse_gbj_admin" ? (
            <div className="wrapper_input">
              <p className="font_weight_bold">
                Keluar / Masuk<label style={{ color: "red" }}>*</label>
              </p>
              <div style={{ display: "flex" }}>
                <div
                  onClick={() => setStatusBarang("Keluar")}
                  className="wrapper_radio"
                  style={{ marginRight: "24px" }}
                >
                  <input
                    type="radio"
                    value="Keluar"
                    checked={statusBarang === "Keluar"}
                  />
                  <label style={{ fontSize: "14px" }}>Keluar</label>
                </div>
                <div
                  onClick={() => setStatusBarang("Masuk")}
                  className="wrapper_radio"
                >
                  <input
                    type="radio"
                    value="Masuk"
                    checked={statusBarang === "Masuk"}
                  />
                  <label style={{ fontSize: "14px" }}>Masuk</label>
                </div>
              </div>
            </div>
          ) : (
            ""
          )}
          <div className="wrapper_input">
            <p className="font_weight_bold">
              Perusahaan<label style={{ color: "red" }}>*</label>
            </p>
            <select
              onChange={(e) => setCompanyPickInputGbj(e.target.value)}
              className="input_select"
              defaultValue="Pilih Nama Perusahaan"
            >
              <option selected={companyPickInputGbj === ""} disabled>
                Pilih Nama Perusahaan
              </option>
              {dataListBarangJadi &&
                dataListBarangJadi.map((data, i) => (
                  <option
                    value={data.company}
                    key={i}
                    selected={companyPickInputGbj === data.company}
                  >
                    {data.company}
                  </option>
                ))}
            </select>
          </div>
          <div className="wrapper_input">
            <p className="font_weight_bold">
              Nama Barang<label style={{ color: "red" }}>*</label>
            </p>
            <div className="select_item_gbj">
              <Select
                options={reFormat}
                value={produkPick || ""}
                onChange={setProdukPick}
              />
            </div>
          </div>
          <div className="wrapper_input">
            <p className="font_weight_bold">
              Jumlah (Pcs)<label style={{ color: "red" }}>*</label>
            </p>
            <input
              type="number"
              className="input_jumlah"
              placeholder="Masukkan Jumlah (Pcs)"
              value={jumlahInputGbj}
              onChange={(e) => setJumlahInputGbj(e.target.value)}
              onWheel={(e) => e.target.blur()}
            />
          </div>
          <div style={{ display: "flex" }} className="column_mobile">
            <div className="wrapper_input">
              <p className="font_weight_bold">Keterangan</p>
              <textarea
                className="text_area"
                placeholder="Masukkan Keterangan Jika Diperlukan"
                onChange={(e) => setKeteranganGbj(e.target.value)}
                value={keteranganGbj}
              />
            </div>
            <div className="wrapper_button_kirim_request_in">
              <div
                className="button_kirim_request_in"
                onClick={() => handleKirimInputGbj()}
              >
                Kirim
              </div>
            </div>
          </div>
        </div>
        <div
          className="table_inventory_mobile width-table-karantina"
          style={{ position: "absolute", zIndex: "-1" }}
        >
          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
              marginBottom: "24px",
            }}
          >
            <div className="margin_right_27_qc">
              <p>Pilih Tanggal</p>
              <input
                type="date"
                className="date_pick_memo_ppic"
                style={{ margin: "0px" }}
                value={filterDate}
                onChange={(e) => setFilterDate(e.target.value)}
              />
            </div>
            <div style={{ display: "flex", alignItems: "end" }}>
              <div className="select_item_gbj">
                <p>Pilih Produk</p>
                <Select
                  options={resultReduce}
                  value={produkPickFilter || ""}
                  onChange={setProdukPickFilter}
                />
              </div>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginLeft: "12px",
              }}
            >
              <div>
                <p>Status</p>
                <select
                  defaultValue="Status"
                  onChange={(e) => setFilterStatus(e.target.value)}
                  className="input_select_memo_ppic"
                  style={{ width: "100%" }}
                  value={filterStatus}
                >
                  <option selected disabled>
                    Status
                  </option>
                  <option value="Masuk">Masuk</option>
                  <option value="Keluar">Keluar</option>
                </select>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "end",
              }}
            >
              <p
                className="btn_reset_search_inventory"
                style={{ margin: "0 0 0 12px" }}
                onClick={() => handleReset()}
              >
                Reset
              </p>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "end",
              }}
            >
              <img
                src={RefreshIcon}
                alt="refresh"
                style={{ width: "36px", marginLeft: "12px", cursor: "pointer" }}
                onClick={() => {
                  setIsReload(true);
                }}
              />
            </div>
          </div>
          <table style={{ width: "100%" }}>
            <tr>
              <th
                className="table_header"
                style={{
                  background: "#e8e9f8",
                  padding: "12px 6px",
                  width: "6%",
                  borderRight: "1px solid lightgray",
                  width: "auto",
                }}
              >
                No
              </th>
              <th
                className="table_header"
                style={{
                  background: "#e8e9f8",
                  borderRight: "1px solid lightgray",
                  padding: "6px",
                  width: "auto",
                }}
              >
                Customer
              </th>
              <th
                className="table_header"
                style={{
                  background: "#e8e9f8",
                  borderRight: "1px solid lightgray",
                  padding: "6px",
                }}
              >
                Produk
              </th>
              <th
                className="table_header"
                style={{
                  background: "#e8e9f8",
                  borderRight: "1px solid lightgray",
                  padding: "6px",
                  width: "auto",
                }}
              >
                Jumlah
              </th>
              <th
                className="table_header"
                style={{
                  background: "#e8e9f8",
                  borderRight: "1px solid lightgray",
                  padding: "6px",
                  width: "auto",
                }}
              >
                Status
              </th>
              <th
                className="table_header"
                style={{
                  background: "#e8e9f8",
                  borderRight: "1px solid lightgray",
                  padding: "6px",
                  width: "auto",
                }}
              >
                Update By
              </th>
              <th
                className="table_header"
                style={{
                  background: "#e8e9f8",
                  borderRight: "1px solid lightgray",
                  padding: "6px",
                }}
              >
                Keterangan
              </th>
            </tr>
            {dataTemp &&
              dataTemp.map((e, index) => {
                return (
                  <tr
                    style={
                      index % 2 === 0
                        ? {
                            background: "white",
                            fontSize: "12px",
                          }
                        : {
                            background: "#f9f9f9",
                            fontSize: "12px",
                          }
                    }
                  >
                    <td
                      className="font_td_laporan_bahan_baku"
                      style={{
                        padding: "12px 6px",
                        lineHeight: "18px",
                      }}
                    >
                      {index + 1}
                    </td>

                    <td
                      className="font_td_laporan_bahan_baku"
                      style={{ padding: "12px 6px" }}
                    >
                      {e && e.company}
                    </td>
                    <td
                      className="font_td_laporan_bahan_baku"
                      style={{ padding: "12px 6px" }}
                    >
                      {e && e.material}
                    </td>
                    <td
                      className="font_td_laporan_bahan_baku"
                      style={{ padding: "12px 6px", letterSpacing: "0.5px" }}
                    >
                      {formatDot(e && e.dataWeight)}
                    </td>
                    <td
                      className="font_td_laporan_bahan_baku"
                      style={
                        e && e.statusBahan === "Masuk"
                          ? { padding: "12px 6px", color: "green" }
                          : { padding: "12px 6px", color: "red" }
                      }
                    >
                      {e && e.statusBahan}
                    </td>
                    <td
                      className="font_td_laporan_bahan_baku"
                      style={{ padding: "12px 6px" }}
                    >
                      {e && e.updatedBy.split(" ").slice(0, 1).join(" ")}
                    </td>
                    <td
                      className="font_td_laporan_bahan_baku"
                      style={{ padding: "12px 6px" }}
                    >
                      {e && e.keterangan}
                    </td>
                  </tr>
                );
              })}
            <tr>
              <td
                className="font_td_laporan_bahan_baku"
                style={{
                  padding: "12px 6px",
                  lineHeight: "18px",
                }}
              ></td>
              <td
                className="font_td_laporan_bahan_baku"
                style={{ padding: "12px 6px" }}
              ></td>
              <td
                className="font_td_laporan_bahan_baku"
                style={{ padding: "12px 6px" }}
              >
                JUMLAH
              </td>
              <td
                className="font_td_laporan_bahan_baku"
                style={{ padding: "12px 6px", letterSpacing: "0.5px" }}
              >
                {formatDot(sum && sum)}
              </td>
              <td
                className="font_td_laporan_bahan_baku"
                style={{ padding: "12px 6px", letterSpacing: "0.5px" }}
              ></td>
              <td
                className="font_td_laporan_bahan_baku"
                style={{ padding: "12px 6px" }}
              ></td>
              <td
                className="font_td_laporan_bahan_baku"
                style={{ padding: "12px 6px" }}
              ></td>
            </tr>
          </table>
          <br />
        </div>
      </>
    </div>
  );
};
export default InputBarangJadi;
